import { useLocation } from "react-router-dom";

export function EmailField({ getEmail, email }) {
  const location = useLocation();
  const isReadOnlyField = location.pathname === "/payments-form";

  return (
    <>
      <div className="custom-field-container">
        <label>Email</label>
        <div>
          <input
            type="email"
            placeholder="Enter your email here"
            id="customField"
            className="p-LinkInput"
            name="email"
            defaultValue={email}
            onChange={getEmail}
            readOnly={isReadOnlyField}
          />
        </div>
      </div>
    </>
  );
}
