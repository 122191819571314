import { AddressElement } from "@stripe/react-stripe-js";

export function AddressForm({ name }) {
  const addressOptions = {
    mode: "billing",
    allowedCountries: ["US"],
    defaultValues: {
      name: name,
    },
  };

  return (
    <>
      <div className="address-container">
        <AddressElement options={addressOptions} />
        <div id="readOnlyField" />
      </div>
    </>
  );
}
