import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AmountField } from "./../custom/AmountField";
import { NameField } from "./../custom/NameField";
import { EmailField } from "./../custom/EmailField";
import { OrderIdField } from "./../custom/OrderIdField";

export function CheckoutForm() {
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const getAmount = useCallback(
    (e) => {
      const value = e.target.value;
      let cleanedValue = value.replace(/[,.]/g, ".");
      const parts = cleanedValue.split(".");

      if (cleanedValue.startsWith(".")) {
        cleanedValue = "0" + cleanedValue;
      }

      if (parts.length > 2 || (parts[1] && parts[1].length >= 3)) {
        const integerPart = parts[0].replace(/\./g, "");
        const decimalPart = parts[1] ? parts[1] : "";
        const finalValue = parseInt(integerPart + decimalPart, 10).toString();

        setAmount(finalValue);
      } else if (parts.length > 1) {
        const integerPart = parts[0].replace(/\./g, "");
        const decimalPart = parts[1].substring(0, 2);
        const finalValue = `${parseInt(integerPart, 10)}.${decimalPart}`;

        setAmount(finalValue);
      } else {
        const finalValue = cleanedValue.replace(/\./g, "");

        setAmount(parseInt(finalValue, 10).toString());
      }
    },
    [setAmount]
  );

  const getOrderId = useCallback(
    (e) => {
      setOrderId(e.target.value);
    },
    [setOrderId]
  );

  const getName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const getEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const onSubmitCheckout = useCallback(
    async (e) => {
      e.preventDefault();

      navigate("/payments-form", { state: { amount, orderId, name, email } });
    },
    [amount, email, name, navigate, orderId]
  );

  const isAmountValid = /^[0-9]*(\.[0-9]*)?$/.test(amount);

  return (
    <>
      <div className="checkout-container">
        <img
          alt="logo"
          title="Lamborghini logo"
          className="logo-img"
          src="./images/logo-new.png"
        />
        <form onSubmit={onSubmitCheckout}>
          <p className="form-title">Personal details and order Id</p>
          <NameField getName={getName} name={name} />
          <div className="separator"></div>
          <EmailField getEmail={getEmail} email={email} />
          <div className="separator"></div>
          <OrderIdField getOrderId={getOrderId} orderId={orderId} />
          <div className="separator"></div>
          <AmountField onAmountChange={getAmount} amount={amount} />
          <input
            type="submit"
            value="Submit"
            className="btn btn-success checkout-btn"
            disabled={
              !name ||
              !email ||
              !amount ||
              !orderId ||
              amount < 0.5 ||
              !isAmountValid
            }
          />
        </form>
      </div>
    </>
  );
}
