import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getAccessToken, createPaymentIntent } from "./utils/tokenUtils";
import ConfirmTokenForm from "./confirmTokenForm";
import { tokenUrl, paymentUrl, clientCredentials } from "./constants/constants";

const stripePromise = loadStripe(
  "pk_live_51OkRuQFFgbwqOsI0Jmk7eMYfO80CeUD9Y7aigWhHAB4SOwBZQEFVeFOwPGHgz5rjVxYPLhDGgsRhT65qLcCArhBp00X6gU8CfB"
);

export function PaymentsComponent() {
  const [stripeOptions, setStripeOptions] = useState();
  const [correlationId, setCorrelationId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const { name, email, amount, orderId } = location.state;

  const getAccessTokenAndSetCorrelationId = useCallback(async () => {
    const data = await getAccessToken(tokenUrl, clientCredentials);
    const newCorrelationId = `Lambo-test-${uuidv4()}`;

    setCorrelationId(newCorrelationId);
    setAccessToken(data.access_token);

    return { accessToken: data.access_token, correlationId: newCorrelationId };
  }, []);

  const createIntentPayment = useCallback(
    async (accessToken, correlationId) => {
      const paymentIntent = await createPaymentIntent(
        paymentUrl,
        accessToken,
        correlationId,
        amount,
        orderId,
        name,
        email,
        "https://squadracorse-na.com/"
      );

      console.log(paymentIntent);

      const options = {
        clientSecret: paymentIntent.data.PaymentIntentCreate.ClientSecret,
        paymentId: paymentIntent.data.PaymentIntentCreate.PaymentIntentId,
      };

      setStripeOptions(options);
      setPaymentIntentId(options.paymentId);
    },
    [amount, email, name, orderId]
  );

  const getToken = useCallback(async () => {
    const { accessToken, correlationId } =
      await getAccessTokenAndSetCorrelationId();

    await createIntentPayment(accessToken, correlationId);
    console.log(accessToken);
  }, [createIntentPayment, getAccessTokenAndSetCorrelationId]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
    getToken();
  }, [getToken]);

  return (
    <main className="main-container">
      {loading ? (
        <img
          alt="logo"
          title="Lamborghini logo"
          className="loading-icon"
          src="./images/loading.gif"
        />
      ) : stripeOptions ? (
        <>
          <img
            alt="logo"
            title="Lamborghini logo"
            className="logo-img"
            src="./images/logo-new.png"
          />
          <Elements stripe={stripePromise} options={stripeOptions}>
            <ConfirmTokenForm
              name={name}
              email={email}
              amount={amount}
              orderId={orderId}
              accessToken={accessToken}
              paymentIntentId={paymentIntentId}
              correlationId={correlationId}
            />
          </Elements>
        </>
      ) : (
        <></>
      )}
    </main>
  );
}
