import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export function SuccessfulPayment({ paymentIntentId }) {
  return (
    <>
      <FontAwesomeIcon icon={faCheck} />
      <div className="successful-container">
        <p className="successful-title">Your payment was successful!</p>
        <p className="success-payment-id">
          Your payment ID is {paymentIntentId}
        </p>
        <a className="back-to-login btn btn-success" href="/">
          Go back to login <span>&#x2936;</span>
        </a>
      </div>
    </>
  );
}
