import { useLocation } from "react-router-dom";

export function NameField({ getName, name }) {
  const location = useLocation();
  const isReadOnlyField = location.pathname === "/payments-form";

  return (
    <>
      <div className="custom-field-container">
        <label>Full name</label>
        <div>
          <input
            type="text"
            placeholder="Enter your name here"
            id="customField"
            className="p-LinkInput"
            name="name"
            defaultValue={name}
            onChange={getName}
            readOnly={isReadOnlyField}
          />
        </div>
      </div>
    </>
  );
}
