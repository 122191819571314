import { useCallback, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { SuccessfulPayment } from "./payment-status/SuccessfulPayment";
import { DeclinedPayment } from "./payment-status/DeclinedPayment";
import { confirmPaymentIntent } from "./utils/tokenUtils";
import { paymentUrl } from "./constants/constants";
import { AddressForm } from "./AddressForm";
import { AmountField } from "./custom/AmountField";
import { OrderIdField } from "./custom/OrderIdField";
import { EmailField } from "./custom/EmailField";

export default function ConfirmTokenForm({
  onAmountChange,
  getOrderId,
  getEmail,
  amount,
  name,
  email,
  orderId,
  accessToken,
  paymentIntentId,
  correlationId,
}) {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleError = useCallback((error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);

      if (!stripe || !elements) {
        return;
      }

      const { error: submitError } = await elements.submit();

      if (submitError) {
        handleError(submitError);
        return;
      }

      const result = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: `https://lamborghinipayments.squadracorse-na.com/`,
        },
      });

      if (result.error) {
        setErrorMessage(result.error.message);
      } else {
        try {
          const confirmResult = await confirmPaymentIntent(
            paymentUrl,
            accessToken,
            paymentIntentId,
            result.confirmationToken.id,
            correlationId,
            orderId,
            name,
            email
          );

          const isSuccess = confirmResult?.data?.PaymentIntentConfirm?.OperationResult?.IsSuccess;

          if (isSuccess) {
            setShowSuccessPopup(true);
          } else {
            const errorData = confirmResult?.data?.PaymentIntentConfirm?.OperationResult?.Errors[0];
            
            setErrorMessage(errorData?.ErrorMessage);
            setShowDeclinePopup(true);
          }
        } catch (error) {
          console.error(error);

          setErrorMessage("Payment was declined!");
          setShowDeclinePopup(true);
        }
      }
    },
    [
      accessToken,
      correlationId,
      elements,
      email,
      handleError,
      name,
      orderId,
      paymentIntentId,
      stripe,
    ]
  );

  return (
    <div>
      {showSuccessPopup && (
        <div className="popup success-popup">
          <SuccessfulPayment paymentIntentId={paymentIntentId} />
        </div>
      )}
      {showDeclinePopup && (
        <div className="popup declined-popup">
          <DeclinedPayment errorMessage={errorMessage} />
        </div>
      )}
      {!showSuccessPopup && !showDeclinePopup && (
        <form onSubmit={handleSubmit} className="main-form">
          <p className="form-title">Billing information</p>
          <div className="separator"></div>
          <AddressForm name={name} />
          <div className="separator"></div>
          <EmailField getEmail={getEmail} email={email} />
          <div className="separator"></div>
          <p className="form-title">Payment information</p>
          <div className="separator"></div>
          <OrderIdField getOrderId={getOrderId} orderId={orderId} />
          <div className="separator"></div>
          <PaymentElement />
          <div className="separator"></div>
          <AmountField onAmountChange={onAmountChange} amount={amount} />
          <div className="separator"></div>
          <button
            type="submit"
            id="submitButton"
            className="btn btn-success"
            disabled={!stripe || loading}
          >
            Submit
          </button>
          {errorMessage && (
            <div className="error-message">
              {errorMessage}
            </div>
          )}
        </form>
      )}
    </div>
  );
}
