import "./styles/App.css";
import "./styles/index.css";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProtectionPage } from "./protection/ProtectionPage";
import { CheckoutForm } from "./checkout/CheckoutForm";
import { PaymentsComponent } from "./PaymentsComponent";
import { SuccessfulPayment } from "./payment-status/SuccessfulPayment";
import { DeclinedPayment } from "./payment-status/DeclinedPayment";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectionPage />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/payments-form" element={<PaymentsComponent />} />
        <Route path="/successful-payment" element={<SuccessfulPayment />} />
        <Route path="/declined-payment" element={<DeclinedPayment />} />
      </Routes>
    </Router>
  );
}

root.render(<App />);
