import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export function DeclinedPayment({ errorMessage }) {
  return (
    <>
      <FontAwesomeIcon icon={faClose} />
      <div className="declined-container">
        <p className="declined-title">{errorMessage}</p>
        <a className="back-to-payment btn btn-danger" href="/checkout">
          Go back to payment details <span>&#x2936;</span>
        </a>
      </div>
    </>
  );
}
